import { apiRequest } from "@launerlondon/shared";
import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { twJoin } from "tailwind-merge";

type Field = {
	title: string;
	type?: React.InputHTMLAttributes<HTMLInputElement>["type"];
	name?: string;
	required?: boolean;
	placeholder?: string;
};
type Props = { action: string; fields: Field[] };

enum FormStatus {
	IDLE = 0,
	SENDING = 1,
	ERROR = 2,
	SENT = 3,
}

function getFieldName(field: Field) {
	return field.name || field.title.toLowerCase().replace(/ /g, "-");
}

const MessageForm: React.FC<Props> = (props) => {
	const [status, setStatus] = useState(FormStatus.IDLE);

	const onFormSubmit = useCallback(
		(event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault();
			const form = new FormData(event.currentTarget);
			const fields = props.fields.reduce(
				(p, c) => {
					const name = getFieldName(c);
					const value = form.get(name)?.toString();
					if (value) p[name] = value;
					return p;
				},
				{} as Record<string, string | undefined>,
			);
			setStatus(FormStatus.SENDING);
			toast.promise(
				apiRequest.post("/api/message", {
					data: {
						action: props.action,
						fields,
					},
				}),
				{
					loading: "sending message",
					error() {
						setStatus(FormStatus.ERROR);
						return "something wrong happened while sending your message, please try again";
					},
					success() {
						setStatus(FormStatus.SENT);
						return "message sent successfully";
					},
				},
			);
		},
		[],
	);
	if (status === FormStatus.SENT) {
		return (
			<div className="mt-4 space-y-2 border-t pt-4">
				<h4 className="font-sans font-light uppercase ">
					Thank you for contacting Launer.
				</h4>
				<p>
					Your message has been successfully sent and will be
					carefully reviewed.
				</p>
			</div>
		);
	}
	return (
		<form className="my-5 space-y-5" onSubmit={onFormSubmit}>
			{props.fields.map(({ type, ...f }) => (
				<label
					className={twJoin(
						type === "checkbox"
							? "flex max-w-max flex-row-reverse items-center gap-2"
							: "ln-field",
					)}
					key={f.title}
				>
					<span>
						{f.title} {f.required && "(required)"}
					</span>
					{type === "textarea" ? (
						<textarea {...f} name={getFieldName(f)} />
					) : (
						<input
							{...f}
							type={type || "text"}
							name={getFieldName(f)}
						/>
					)}
				</label>
			))}
			<button
				className="button ml-0 max-w-0"
				disabled={status === FormStatus.SENDING}
			>
				{status === FormStatus.SENDING ? "Sending…" : "Send"}
			</button>
		</form>
	);
};

export default MessageForm;
