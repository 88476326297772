import { Localized } from "@launerlondon/l10n";
import type { CartItemSnapOption, Product } from "@launerlondon/products";
import {
	defaultRoundFactor,
	findSku,
	fmtSku,
	getItemOptionTotal,
} from "@launerlondon/shared";
import { useConfig } from "@launerlondon/shop-hooks";
import type { RootState } from "@launerlondon/shop-types";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import imageInfoEmblemPurse from "../../assets/info-emblem-purse.jpg";
import imageInfoEmblemCrystalPurse from "../../assets/info-emblem-crystal-purse.webp";
import imageInfoFoilingPassport from "../../assets/info-foiling-passport.jpg";
import imageInfoGoldCorners from "../../assets/info-gold-corners.jpg";
import imageInfoLetteringBag from "../../assets/info-lettering-bag.jpg";
import imageInfoLetteringBgWallet from "../../assets/info-lettering-wallet.jpg";
import imageInfoStrapStyle from "../../assets/info-strap-style.jpg";
import imageInfoShielding from "../../assets/info-shielding.jpg";
import phoneModels from "../../assets/phone-models.json";
import tabletModels from "../../assets/tablet-models.json";
import InfoModal from "./InfoModal";
import LetteringInput from "./LetteringInput";
import SelectInput from "./SelectInput";
import SwatchSelector from "./SwatchSelector";
import VoucherSelectInput from "./VoucherSelectInput";

const ProductItemSelectors: React.FC<{
	product: Product;
	onChange: (option: CartItemSnapOption) => void;
}> = ({ product, onChange }) => {
	const currency = useSelector(
		(s: RootState) => s.locale.currency,
	).toLowerCase() as "gbp";
	const country = useSelector((s: RootState) => s.locale.country);
	const { sku, multipliers, options } = product;
	const { group } = fmtSku(sku);
	const f = findSku.bind(null, sku);
	const skus = useConfig();
	const hasWalletSize = f(skus.withWalletSize);
	const hasPhoneSize = f(skus.withPhoneSize);
	const hasTabletSize = f(skus.withTabletSize);
	const hasLining = f(skus.withLining);
	const allowDoubleGoldCorners = f(skus.withSingleGoldCorners) === undefined;
	const hasPassportLabel = f(skus.withPassportFoiling);
	const hasStrapFitting = f(skus.withStrapFitting);
	const hasPalladiumFittings = f(skus.withPalladiumFittings);
	const hasStrapStyle = f(skus.withStrapStyle);
	const hasEmblem = f(skus.withEmblem);
	const hasCrystalEmblem = f(skus.withCrystalEmblem);
	const isVoucher = f(skus.vouchers);

	const setOption = useCallback(
		(option: CartItemSnapOption) => {
			onChange(option);

			return getItemOptionTotal(
				{ multiplier: option.multiplier },
				product.price,
				defaultRoundFactor,
			)[currency];
		},
		[country, currency],
	);

	const hasOptions = [
		Boolean(options.swatches.length),
		hasWalletSize,
		hasPhoneSize,
		hasTabletSize,
		hasLining,
		hasPassportLabel,
		hasStrapFitting,
		hasEmblem,
		options.gold_corners,
		options.shielding,
		options.lettering,
		isVoucher,
	].some(Boolean);

	if (!hasOptions) return null;

	return (
		<div className="text-xs lg:text-sm">
			<h4 className="mt-8 pb-2 text-sm uppercase tracking-wider text-gray-600">
				<Localized id="product-customise_section-title" />
			</h4>
			<div>
				<div className="divide-y border-y">
					{options.swatches.map((o, _, arr) => (
						<SwatchSelector
							key={o.label}
							label={o.label}
							items={o.available}
							initialValue={o.selected}
							onChange={(swatch) => {
								const swatchId = swatch.id.toUpperCase();
								const initial = arr.find(
									(s) => s.label === o.label,
								);
								const part = initial?.preview_id?.replace(
									"-bg",
									"",
								);
								return setOption({
									type: "swatch",
									multiplier: swatch.id.startsWith("EY")
										? multipliers.python
										: swatch.id.startsWith("EL")
										? multipliers.lizard
										: swatch.id.startsWith("CR")
										? multipliers.croc
										: 0,
									value: swatch.name,
									default: swatch.id === initial?.selected,
									meta: {
										id: swatchId,
										label: o.label,
										previewId: initial?.preview_id,
										thumb: `https://assets.launer.com/images/preview/${group}_${swatchId}_${part}_1280x1280.webp`,
									},
								});
							}}
						/>
					))}
				</div>
				<div className="mt-2 flex flex-col gap-2">
					{hasWalletSize && (
						<SelectInput
							id="wallet_size"
							label="product-selector-wallet_size-label"
							options={[
								{
									label: "product-selector-wallet_size-option--standard",
									value: "standard",
								},
								{
									label: "product-selector-wallet_size-option--us",
									value: "us",
								},
							]}
							onChange={(value: string) =>
								setOption({
									type: "wallet_size",
									value: value,
									multiplier: 0,
									default: true,
								})
							}
						/>
					)}
					{hasPhoneSize && (
						<SelectInput
							id="phone_size"
							label="product-selector-phone_size-label"
							options={phoneModels.map((o) => ({
								label: o.label,
								value: o.label,
							}))}
							onChange={(value: string) =>
								setOption({
									type: "phone_size",
									value,
									multiplier: 0,
									default: true,
								})
							}
						/>
					)}
					{hasTabletSize && (
						<SelectInput
							id="tablet_size"
							label="product-selector-tablet_size-label"
							options={tabletModels.map((o) => ({
								label: o.label,
								value: o.label,
							}))}
							onChange={(value: string) =>
								setOption({
									type: "tablet_size",
									value,
									multiplier: 0,
									default: true,
								})
							}
						/>
					)}
					{hasLining && (
						<SelectInput
							id="lining"
							label="product-selector-lining-label"
							options={[
								"Beige",
								"Black",
								"Burgundy",
								"Green",
								"Grey",
								"Navy",
								"Purple",
								"Red",
								"Tan",
							].map((o) => ({ label: o, value: o }))}
							onChange={(value: string) => {
								return setOption({
									type: "lining",
									value,
									multiplier: 0,
								});
							}}
						/>
					)}
					{hasPassportLabel && (
						<SelectInput
							id="foiling_passport"
							label="product-selector-foiling_passport-label"
							labelSuffix={
								<InfoModal
									title="product-selector-foiling_passport-label"
									image={imageInfoFoilingPassport}
								>
									<Localized id="product-popup-foiling_passport-text" />
								</InfoModal>
							}
							options={[
								{
									label: "product-selector-shielding-option--no",
									value: undefined,
								},
								{
									label: "product-selector-shielding-option--yes",
									value: true,
								},
							]}
							onChange={(value?: true) =>
								setOption({
									type: "foiling_passport",
									value,
									multiplier: 0,
								})
							}
						/>
					)}
					{(hasStrapFitting || hasPalladiumFittings) && (
						<SelectInput
							id="strap_fitting"
							label="product-selector-strap_fitting-label"
							defaultValue={
								hasPalladiumFittings ? "palladium" : "gold"
							}
							options={[
								{
									label: "product-selector-strap_fitting-option--gold",
									value: "gold",
								},
								{
									label: "product-selector-strap_fitting-option--palladium",
									value: "palladium",
								},
							]}
							onChange={(value: string) => {
								const previewId = "fittings";
								const swatchId =
									value === "gold" ? "MT-01" : "MT-02";
								return setOption({
									type: "strap_fitting",
									value,
									default: hasPalladiumFittings
										? value === "palladium"
										: value === "gold",
									multiplier: 0,
									meta: {
										id: previewId,
										label: value,
										previewId,
										thumb: `https://assets.launer.com/images/preview/${group}_${swatchId}_${previewId}_1280x1280.webp`,
									},
								});
							}}
						/>
					)}
					{hasStrapStyle && (
						<SelectInput
							id="strap_style"
							label="product-selector-strap_style-label"
							labelSuffix={
								<InfoModal
									title="product-popup-strap_style-title"
									image={imageInfoStrapStyle}
								>
									<Localized id="product-popup-strap_style-text" />
								</InfoModal>
							}
							options={[
								{
									label: "product-selector-strap_style-option--leather",
									value: "leather",
								},
								{
									label: "product-selector-strap_style-option--gold-chain",
									value: "gold-chain",
								},
							]}
							onChange={(value: string) => {
								return setOption({
									type: "strap_style",
									value,
									default: value === "leather",
									multiplier: 0,
								});
							}}
						/>
					)}
					{(hasEmblem || hasCrystalEmblem) && (
						<SelectInput
							id="emblem"
							label="product-selector-emblem-label"
							labelSuffix={
								<InfoModal
									title="product-popup-emblem-title"
									image={
										hasCrystalEmblem
											? imageInfoEmblemCrystalPurse
											: imageInfoEmblemPurse
									}
								>
									<Localized id="product-popup-emblem-text" />
								</InfoModal>
							}
							options={Array(
								{
									label: "product-selector-emblem-option--rope",
									value: "rope",
								},
								{
									label: "product-selector-emblem-option--flat",
									value: "flat",
								},
								hasCrystalEmblem
									? {
											label: "product-selector-emblem-option--crystal",
											value: "crystal",
									  }
									: false,
							)}
							onChange={(value: string) =>
								setOption({
									type: "emblem",
									value,
									multiplier:
										value === "crystal"
											? multipliers.emblem_crystal
											: 0,
									default: value === "rope",
								})
							}
						/>
					)}
					{options.gold_corners && (
						<SelectInput
							id="gold_corners"
							label="product-selector-gold_corners-label"
							labelSuffix={
								<InfoModal
									title="product-popup-gold_corners-title"
									image={imageInfoGoldCorners}
								>
									<Localized id="product-popup-gold_corners-text" />
								</InfoModal>
							}
							options={[
								{
									label: "product-selector-gold_corners-option--no",
									value: 0,
								},
								{
									label: "product-selector-gold_corners-option--one",
									value: 1,
								},
								allowDoubleGoldCorners && {
									label: "product-selector-gold_corners-option--two",
									value: 2,
								},
							]}
							onChange={(value: number) =>
								setOption({
									type: "gold_corners",
									value,
									multiplier:
										[
											0,
											multipliers.gold_corners_single,
											multipliers.gold_corners_double,
										][value] || 0,
									default: value === 0,
									meta: {
										id: "corners",
										label: `${value}-pair`,
										previewId: "corners",
										thumb: `https://assets.launer.com/images/preview/${group}_${value}-pair_corners_1280x1280.webp`,
									},
								})
							}
						/>
					)}
					{options.shielding && (
						<SelectInput
							id="shielding"
							label="product-selector-shielding-label"
							labelSuffix={
								<InfoModal
									title="product-popup-shielding-title"
									image={imageInfoShielding}
								>
									<Localized id="product-popup-shielding-text" />
								</InfoModal>
							}
							options={[
								{
									label: "product-selector-shielding-option--no",
									value: undefined,
								},
								{
									label: "product-selector-shielding-option--yes",
									value: true,
								},
							]}
							onChange={(value?: true) =>
								setOption({
									type: "shielding",
									value,
									multiplier: value
										? multipliers.shielding
										: 0,
								})
							}
						/>
					)}
					{options.lettering && (
						<LetteringInput
							label="product-selector-lettering-label"
							placeholder="product-selector-lettering-placeholder"
							labelSuffix={
								<InfoModal
									title="product-popup-lettering-title"
									image={
										options.gold_corners
											? imageInfoLetteringBgWallet
											: imageInfoLetteringBag
									}
								>
									<Localized id="product-popup-lettering-text" />
								</InfoModal>
							}
							limit={20}
							onChange={(value) => {
								const size = value?.length || 0;
								const multiplier =
									size === 0
										? 0
										: size < 10
										? multipliers.lettering_short
										: multipliers.lettering_long;
								return setOption({
									type: "lettering",
									value,
									multiplier,
								});
							}}
						/>
					)}
					{isVoucher && (
						<VoucherSelectInput
							onChange={(value, label) => {
								setOption({
									type: "voucher_credit",
									value: label,
									multiplier: value,
								});
							}}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default ProductItemSelectors;
