import {
	Countdown,
	Instagram,
	ThreeSixty,
	Video,
} from "@launerlondon/shop-components";
import { useMetaTags } from "@launerlondon/shop-hooks";
import { Link } from "react-router-dom";
import { twJoin, twMerge } from "tailwind-merge";

type SubtitleProps = React.PropsWithChildren & { className?: string };

const Subtitle: React.FC<SubtitleProps> = (props) => {
	return (
		<h4
			className={twMerge(
				"ln-subtitle-sans pt-2 text-xs tracking-[0.35em]",
				props.className,
			)}
		>
			{props.children}
		</h4>
	);
};

const Home: React.FC = () => {
	useMetaTags({
		title: "Launer London | Handmade British Luxury Leather Bags & Goods",
		description:
			"Discover Launer's British handcrafted leather bags, wallets, purses and accessories. Heritage creations crafted from the finest leather since 1941. Shop now.",
		image: "/images/collections/summer-brights-banner.jpg",
		suffix: false,
	});
	return (
		<div className="space-y-2 lg:space-y-5">
			<Link
				to="/cc/summer-brights"
				className={twJoin(
					"relative block",
					"h-screen max-h-[50vh] lg:max-h-[70vh]",
				)}
			>
				<div
					className={twJoin(
						"absolute inset-0",
						"bg-cover bg-[25%_center] bg-no-repeat md:bg-center",
						"bg-[url('/images/collections/summer-brights-banner.jpg')]",
					)}
				/>
				<div
					className={twJoin(
						"absolute inset-x-0 p-2",
						"text-center font-light uppercase leading-relaxed tracking-widest text-white",
						"text-[10px]",
						"bg-[#343030] md:bg-[#343030]/90",
					)}
				>
					Complimentary shipping on handbags / Complimentary lettering
					for a limited period
				</div>
			</Link>
			<div className="container">
				<h1 className="sr-only">Launer London</h1>
				<article className="container space-y-6 py-20 text-center">
					<header className="space-y-2">
						<h2 className="font-display text-xl uppercase tracking-widest">
							Summer Brights
						</h2>
					</header>
					<p className="m-auto max-w-2xl lg:text-lg">
						Vibrant colour is essential in a Summer wardrobe and
						makes a stunning statement when used on handbags. Our
						edit will take you from day to evening in shades to
						flatter and enhance all. Any of these styles and our
						entire collection can be customised choosing from over
						65 colours.
					</p>
					<div className="flex items-center justify-center gap-10 py-4">
						<Link
							className="block hover:text-[#B18385]"
							to="/cc/summer-brights"
						>
							<Subtitle>VIEW THE EDIT</Subtitle>
						</Link>
					</div>
				</article>
			</div>
			<div
				className={twJoin(
					"container",
					"uppercase",
					"grid grid-cols-1 gap-2 md:grid-cols-4 lg:gap-5",
					"text-center text-lg leading-loose tracking-wide text-gray-900",
				)}
			>
				<Link
					to="/n/2024081501/ladylike-style-from-alicia-healey"
					className="flex flex-col gap-2 bg-[#FFF0F0] p-5 text-sm leading-relaxed"
				>
					<div className=" mb-5 flex flex-col items-center justify-center md:h-16">
						<h3 className="font-display mx-auto block max-w-[300px] tracking-widest text-[#343030]">
							LADYLIKE STYLE
						</h3>
						<Subtitle className="text-[#A23C3C]">
							IRIS IN BUTTERMILK
						</Subtitle>
					</div>
					<div
						className={twJoin(
							"relative mx-auto bg-cover bg-right bg-top",
							"w-full flex-1",
							"aspect-square",
						)}
						style={{
							backgroundImage: `url(https://assets.launer.com/images/posts/202408150101_1280x1280.webp)`,
						}}
					>
						<span className="absolute bottom-2 right-2 text-xs lowercase text-white">
							@theladysmaid
						</span>
					</div>
				</Link>
				<Link
					to="/n/2024072301/launer-at-elegance-show-hampton-court-palace"
					className="flex flex-col gap-2 bg-[#FFF0F0] p-5 text-sm leading-relaxed md:col-span-2"
				>
					<div className=" mb-5 flex flex-col items-center justify-center md:h-16">
						<h3 className="font-display mx-auto block tracking-widest text-[#343030]">
							VISIT US AT CONCOURS OF ELEGANCE
						</h3>
						<Subtitle className="text-[#A23C3C]">
							HAMPTON COURT PALACE: 30th AUGUST – 1st SEPTEMBER
						</Subtitle>
					</div>
					<div
						className={twJoin(
							"aspect-[4/3]",
							"bg-cover bg-center",
							"relative",
							"bg-[url('/images/pages/home-feature-2.jpg')]",
						)}
					>
						<Countdown date="2024-08-30T10:00:00.000Z" />
					</div>
				</Link>
				<Link
					className="relative flex flex-col gap-2 bg-[#FFF0F0] p-5 text-sm leading-relaxed"
					to="/p/3124-04/iris-cloud-blue-tan"
				>
					<div className="mb-5 flex flex-col items-center justify-center md:h-16">
						<h3 className="font-display mx-auto block max-w-[300px] tracking-widest text-[#343030]">
							Holiday with Micro bags
						</h3>
						<Subtitle className="text-[#A23C3C]">
							Iris in Cloud Blue
						</Subtitle>
					</div>
					<Video
						videoId="990012749"
						className={twJoin(
							"mx-auto  bg-white bg-cover bg-center bg-no-repeat",
							"w-full flex-1",
							"aspect-square",
							"max-md:aspect-[9/12]",
							"pointer-events-none",
						)}
					/>
					<span className="absolute bottom-6 right-7 z-50 text-xs normal-case text-white">
						@ruxandraiona
					</span>
				</Link>
			</div>
			<div className="container grid grid-cols-1 gap-2 md:grid-cols-2 lg:gap-5">
				<Link
					to="/cc/summer-2024"
					className={twJoin(
						"relative flex flex-col bg-cover bg-center pt-[100%]",
						"bg-[url('/images/pages/home-extra-1.jpg')]",
					)}
				>
					<div
						className={twJoin(
							"absolute text-center",
							"text-sm uppercase tracking-widest text-black",
							"flex items-center justify-center",
							"left-0 top-40 lg:left-6 lg:top-60",
							"max-w-[220px]",
						)}
					>
						<div className="p-2 max-lg:bg-white/90">
							<h4 className="font-display">
								Early summer must-have bags
							</h4>
							<Subtitle className="pt-0 text-[#A28E3C]">
								VIEW THE EDIT
							</Subtitle>
						</div>
					</div>
				</Link>
				<Link
					to="/p/3124-13/iris-sunshine-yellow-royal-blue"
					className={twJoin(
						"relative flex flex-col bg-cover bg-bottom pt-[100%]",
						"bg-[url('/images/pages/home-feature-3.jpg')]",
					)}
				>
					<div
						className={twJoin(
							"absolute text-center",
							"text-sm uppercase tracking-widest text-black lg:text-white",
							"flex items-center justify-center",
							"bottom-6 right-0 lg:right-6",
							"max-w-[120px]",
						)}
					>
						<div className="p-2 max-lg:bg-white/90">
							<h4 className="font-display">SUNSHINE IRIS</h4>
							<Subtitle className="pt-0 text-[#FFDE58]">
								Shop now
							</Subtitle>
						</div>
					</div>
				</Link>
				<Link
					to="/p/3129-03/bella-cloud-blue"
					className={twJoin(
						"relative flex flex-col bg-cover bg-center pt-[80%]",
						"bg-[url('/images/pages/home-feature-1.jpg')]",
					)}
				>
					<div
						className={twJoin(
							"absolute text-center",
							"text-sm uppercase tracking-widest",
							"flex items-center justify-center",
							"right-0 top-[40%] lg:right-10",
							"max-w-[300px]",
						)}
					>
						<div className="p-2 max-lg:bg-white/90 max-lg:text-black">
							<h4 className="font-display">BELLA IN BLUE</h4>
							<Subtitle className="pt-0 max-lg:text-[#B18385]">
								Shop Now
							</Subtitle>
						</div>
					</div>
				</Link>
				<Link
					to="/p/0001-01/gift-card"
					className={twJoin(
						"relative flex flex-col bg-cover bg-center pt-[80%]",
						"bg-[url('/images/pages/home-extra-2.jpg')]",
					)}
				>
					<div
						className={twJoin(
							"absolute text-center",
							"text-sm uppercase tracking-widest text-black",
							"inset-x-0 bottom-4 flex items-center justify-center",
						)}
					>
						<div className="p-2 max-lg:bg-white/90">
							<h4 className="font-display">
								Treat someone special
							</h4>
							<Subtitle className="pt-0 text-[#B18385]">
								THE NEW LAUNER E-GIFT CARD
							</Subtitle>
						</div>
					</div>
				</Link>
			</div>
			<div className="container p-5 text-center uppercase tracking-widest">
				<h3 className="text-lg font-light">
					<Link
						to="/p/3113-12/viola-oyster-satin"
						className="text-[#BBACA0]"
					>
						Viola Oyster (Satin)
					</Link>{" "}
					– through 360º
				</h3>
				<ThreeSixty
					tip="Swipe to rotate"
					className="mx-auto my-5 max-w-2xl rounded pb-[100%] md:pb-[700px]"
					image="https://assets.launer.com/images/360/3113-12/{i}_1280x1280.jpeg"
					count={36}
					loop
				/>
			</div>
			<Instagram className="mb-10 mt-14" />
		</div>
	);
};

export default Home;
