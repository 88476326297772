import { RelatedPages } from "@launerlondon/shop-components";
import { useMetaTags } from "@launerlondon/shop-hooks";
import { Fragment, ReactElement } from "react";
import { ParallaxBanner } from "react-scroll-parallax";
import bannerUrl from "../assets/banner-heritage.jpg";
import image1940Url from "../assets/heritage-1940s.jpg";
import image1950Url from "../assets/heritage-1950s.jpg";
import image1960Url from "../assets/heritage-1960s.jpg";
import image1970Url from "../assets/heritage-1970s.jpg";
import image1980Url from "../assets/heritage-1980s.jpg";
import image1990Url from "../assets/heritage-1990s.jpg";
import image2000Url from "../assets/heritage-2000s.jpg";
import image2010Url from "../assets/heritage-2010s.jpg";
import related3Url from "../assets/related-craftsmanship.jpg";
import related2Url from "../assets/related-responsibility.jpg";
import related1Url from "../assets/related-royalwarrant.jpg";

type Period = {
	title: string;
	image: string;
	caption: string;
	children: ReactElement;
};

const periods: Period[] = [
	{
		title: "Origins – 1940’s",
		image: image1940Url,
		caption:
			"Her Majesty, Queen Elizabeth, with Fred Silberman, CEO of LS Mayer.",
		children: (
			<Fragment>
				<p>
					Founded in the 1940’s with a philosophy to make exceptional
					product, crafted in a meticulous way using the finest
					leathers. The style of the day required a formal, structured
					shape and so it was that Launer built a reputation for fine
					leather goods, despite the restrictions imposed by the
					ongoing war.
				</p>
				<p>
					These exceptional bags soon became favoured and the
					accessory of choice by the elite of the day including
					Royalty, Society and Ambassadors.
				</p>
			</Fragment>
		),
	},
	{
		title: "1950’s",
		image: image1950Url,
		caption: "Window display of Fortnum and Mason.",
		children: (
			<Fragment>
				<p>
					Launer began to supply the leading stores in London. Its
					quality was greatly admired and proved a magnet for skilled
					craftspeople. These skills had often been acquired overseas
					and their input gave the brand a unique international
					dimension which it retained for many years.
				</p>
				<p>
					By now Launer’s exceptional expertise in making exquisite
					leather handbags was being used for other companies, one
					being Gucci. This became the company’s main source of
					business as it became apparent there was a lack of marketing
					expertise in promoting the Launer name. They still continued
					at this stage to stock Harrods and other leading stores with
					Launer product.
				</p>
			</Fragment>
		),
	},
	{
		title: "1960’s",
		image: image1960Url,
		caption: "Princess Margaret on a visit to East Africa.",
		children: (
			<Fragment>
				<p>
					By now HM the Queen was a regular customer directly and
					indirectly – Launer made matching handbags for HM Rayne who
					held the Royal Warrant as a shoe manufacturer. They also
					produced for another maker and when this ceased to trade,
					Launer applied successfully for the Royal Warrant.
				</p>
			</Fragment>
		),
	},
	{
		title: "1970’s",
		image: image1970Url,
		caption:
			"Prime Minister Margaret Thatcher greeted by Lady Docker, noted socialite.",
		children: (
			<Fragment>
				<p>
					These were the chapters of decline in the Launer story.
					Despite its excellent reputation as a manufacturer, the
					company had neglected to stay abreast of the times. Without
					the benefit of marketing and a vision to move forward, the
					company had become dependent on its survival on existing
					customers and had failed to promote its own name. It would
					indeed have gone by the wayside and sought a buyer as a
					survival option.
				</p>
			</Fragment>
		),
	},
	{
		title: "1980’s",
		image: image1980Url,
		caption:
			"Her Majesty Queen Elizabeth, the Queen Mother attends an official function.",
		children: (
			<Fragment>
				<p>
					Launer was rescued from extinction in 1981 by Gerald Bodmer,
					a successful businessman who had two decades of experience
					in the gifts and leather goods business. His started his own
					company in 1968 and by 1970 had begun handbag manufacturing
					in Shoreditch supplying bags for Russell and Bromley, Bally
					and Mappin and Webb.
				</p>
				<p>
					Gerald is the first to acknowledge that rescuing Launer was
					an ‘act of faith’. It was in a sorry way, it had financial
					difficulties, lacked management, no own label presence and
					existed primarily as a sub-contractor. It was in real danger
					of sliding into obscurity.
				</p>
				<p>
					Investment of time and money to modernise production and the
					Launer name was required along with a vision; Gerald Bodmer
					had that vision. Whilst other brands flocked abroad to
					manufacture for economic reasons, Launer steadfastly
					remained loyal to British manufacturing and maintained its
					Walsall factory despite all signs indicating this was to be
					an uphill challenge.
				</p>
				<p>
					He worked tirelessly in promoting the brand and keeping its
					assets intact. The collection changed but the distinctive
					Launer signature look was kept. The process of
					reinvigorating the Launer brand had begun. HM the Queen
					favoured Launer over any other brand and continued carrying
					Launer at each and every public and state occasion.
				</p>
			</Fragment>
		),
	},
	{
		title: "1990’s",
		image: image1990Url,
		caption:
			"Her Majesty the Queen visits the Launer factory, pictured here with Mr Gerald Bodmer CEO Launer.",
		children: (
			<Fragment>
				<p>
					Launer was now firmly back at the top of its league, through
					its marketing strategy, established as a luxury brand of
					distinction with international dignitaries and royalty
					choosing Launer as the accessory for state and public
					functions. Under the guidance of Gerald and his wife Susan,
					with a significant tactics was introduced, all product would
					bear the name of Launer which would once again come become
					associated with luxury, aspiration and a celebration of
					understated fine leather goods.
				</p>
				<p>
					Premier department stores once again stocked Launer both in
					the UK and globally.
				</p>
			</Fragment>
		),
	},
	{
		title: "2000’s",
		image: image2000Url,
		caption:
			"Mr Gerald Bodmer CEO of Launer, pictured in the Launer factory.",
		children: (
			<Fragment>
				<p>
					The market had shifted and the culture was now about catwalk
					designers producing handbag collections. In this climate
					Launer was viewed as traditional with its department store
					clients preferring a concession led option and any buying
					space reserved for the catwalk designers of the day.
				</p>
				<p>
					Times were challenging and Launer was resigned to becoming a
					brand of the past.
				</p>
				<p>
					With the advent of the internet the company sought to launch
					a web based business that could serve the customer needs
					directly and so in 2009, Launer.com was launched.
				</p>
			</Fragment>
		),
	},
	{
		title: "2010 onwards",
		image: image2010Url,
		caption: "Launer press event launching new season’s collection.",
		children: (
			<Fragment>
				<p>
					Launer began to gather momentum with its web based presence
					with small leather goods the most popular items.
				</p>
				<p>
					With positive feedback it became apparent that Launer had a
					viable presence in the current luxury landscape. The
					collection was a homage to black and brown and took a
					momentous decision; It would take its best selling hero bag,
					the Traviata and celebrate the iconic design by interpreting
					into stunning bright colours.
				</p>
				<p>
					At the same time 2011, HM The Queen carried a Launer bag at
					the wedding of Prince William to Kate Middleton.
				</p>
				<p>
					Something of a phenomenon occurred. The website crashed with
					the demand of consumers wanting to find out more about this
					heritage brand that adorned HM The Queen. Very soon it
					became known that the Launer website had received
					unprecedented traffic with the result that it went viral.
					From the USA to New Zealand everyone wanted to know about
					the British brand that had been making exquisite handbags
					since the 1940’s.
				</p>
				<p className="border-b border-t py-4 font-sans text-lg font-light">
					With its colour injection and increased profile it became
					the hottest brand to be featured in luxury magazines with
					column inches dedicated to its fascinating legacy and
					association with The Queen. From Vogue, Tatler, Financial
					Times to the Daily Telegraph they all wanted to embrace
					Launer products and explore the story behind this British
					institution.
				</p>
				<p>
					Launer became one of the most feted brands stocked by
					Selfridges and Fortnum and Mason and appeared on Broadway in
					the award winning play Her Majesty with Helen Mirren. The
					distinctive Launer bag has featured in other prominent
					films, stage plays and in exhibitions.
				</p>
				<p>
					The appeal of Launer transcends fashion and has become a
					legacy of great British craftmanship and enduring global
					appeal.
				</p>
			</Fragment>
		),
	},
];

const Period: React.FC<Period> = (props) => {
	return (
		<article className="border-b-2 pb-10">
			<h3 className="mb-6 border-b pb-4 font-sans text-lg font-light">
				{props.title}
			</h3>
			<div className="ln-section-grid gap-8">
				<figure>
					<img src={props.image} className="block w-full" />
					<figcaption className="ln-figcaption">
						<p>{props.caption}</p>
					</figcaption>
				</figure>
				<div>{props.children}</div>
			</div>
		</article>
	);
};

const Heritage: React.FC = () => {
	useMetaTags({
		title: "Heritage Story",
		description:
			"The story of Launer is as illustrious as its fine leather goods. It is a celebration of heritage that spans more than 80 glorious years of crafting exquisite goods.",
		image: bannerUrl,
	});
	return (
		<div>
			<ParallaxBanner
				layers={[
					{
						speed: -20,
						expanded: false,
						children: (
							<div
								className="ln-banner-image"
								style={{ backgroundImage: `url(${bannerUrl}` }}
							/>
						),
					},
				]}
				className="ln-banner"
			/>
			<div className="container">
				<header className="ln-intro-grid">
					<h2>Heritage Story</h2>
					<p>
						The story of Launer is as illustrious as its fine
						leather goods. It is a celebration of heritage that
						spans more than 80 glorious years of crafting exquisite
						goods.
					</p>
				</header>
				<div className="space-y-10">
					{periods.map((p) => (
						<Period key={p.title} {...p} />
					))}
				</div>
			</div>
			<RelatedPages
				pages={[
					{
						title: "Royal Warrant",
						link: "/royal-warrant",
						image: related1Url,
					},
					{
						title: "Corporate responsibility",
						link: "/corporate-responsibility",
						image: related2Url,
					},
					{
						title: "Craftsmanship",
						link: "/craftsmanship",
						image: related3Url,
					},
				]}
			/>
		</div>
	);
};

export default Heritage;
