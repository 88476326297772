import { Instagram, Products, Row } from "@launerlondon/shop-components";
import { useMetaTags } from "@launerlondon/shop-hooks";
import { Link } from "react-router-dom";

export default function CollectionRangeRoverHouse() {
	useMetaTags({
		title: "Range Rover House",
		description: "",
		image: "/images/collections/range-rover-house-banner.jpg",
	});
	return (
		<div className="text-center">
			<Row.WithImage
				reverse
				image="/images/collections/range-rover-house-banner.jpg"
				className="relative bg-black text-white"
				containerClassName="xl:grid-cols-1"
				contentClassName="relative grid place-items-center gap-6 lg:py-24 lg:px-0"
				imageClassName="lg:absolute lg:h-full lg:right-0"
			>
				<img
					className="w-28"
					src="/images/collections/range-rover-house-royal.png"
				/>
				<h1 className="gris-rows-2 grid">
					<span className="ln-title text-4xl">A CELEBRATION</span>
					<span className="ln-subtitle">
						OF BRITISH HERITAGE LEATHER GOODS
					</span>
				</h1>
				<h2 className="font-sans text-sm uppercase tracking-widest">
					A Royal Warrant Holder Since 1968
				</h2>
			</Row.WithImage>
			<Row.Single
				className="border-b"
				contentClassName="max-w-3xl lg:py-20"
			>
				<p className="text-lg">
					Welcome to the world of Launer and discover a British legacy
					built on tradition and fine craftsmanship. Celebrating over
					eighty years of heritage and creating the very finest in
					handmade, understated leather goods which employ time
					honoured skills.
				</p>
				<p>
					Launer’s association with Her Majesty Queen Elizabeth II
					goes back more than six decades and in 1968 was granted a
					Royal Warrant. Queen Elizabeth carried only Launer handbags
					to events, functions, state visits and ceremonies right up
					until 2022. Her outfits and her Launer bags became cultural
					style icons on a global scale to the extent that they were
					depicted in major events such as The Platinum Jubilee in
					2022, Television dramas, on Broadway and enjoyed countless
					posts, shares and tags on social media.
				</p>
				<p>
					Launer is proud to have served Her Majesty as well as Queen
					Camilla and now presents three styles that were beloved
					favourites and visible at high profile occasions.
				</p>
			</Row.Single>
			<Products
				title="EXHIBITED PRODUCTS"
				className="container"
				skus={["3050-01", "3138-01", "3137-01"]}
			/>
			<Row.WithImage
				className="bg-[#862B64] text-[#EBE7E7]"
				reverse
				image="/images/collections/range-rover-house-traviata.jpg"
				contentClassName="space-y-14"
			>
				<h2 className="ln-subtitle">Traviata</h2>
				<p>
					The Traviata is a legend, acclaimed for its structured
					silhouette, exquisite handcrafted detail and signature
					twisted rope emblem. This handbag icon was first introduced
					in 1989, achieving International status as the bag of choice
					for Her Majesty Queen Elizabeth II and is the style most
					associated with Queen Elizabeth and her remarkable style. It
					forms part of the distinguished Legacy Collection. This
					impeccable design transcends time remaining stylish and
					contemporary, the hallmark of a true investment piece. It
					has been featured in films, illustrations, on cakes and in
					other guises resulting in its recognition as a style icon.
				</p>
				<Link
					to="/s/traviata"
					className="inline-block text-sm uppercase tracking-widest"
				>
					Explore
				</Link>
			</Row.WithImage>
			<Row.WithImage
				className="bg-[#EDE3AE] text-[#343030]"
				image="/images/collections/range-rover-house-eleanor.jpg"
				contentClassName="space-y-14"
			>
				<h2 className="ln-subtitle">Eleanor</h2>
				<p>
					An exceptional double handle day bag with a poignant
					association, it is the exact model Her Majesty Queen
					Elizabeth II carried at the wedding of the Prince and
					Princess of Wales in 2011, in stunning bone white, a
					departure from Queen Elizabeth’s usual black.  Eleanor, not
					previously available, is a suitable homage to Queen
					Elizabeth II and features a gold-plated framed interior in a
					serene shade of Bone White and our signature twisted rope
					emblem. It forms part of The Elizabeth Collection.
				</p>
				<Link
					to="/p/3138-01"
					className="inline-block text-sm uppercase tracking-widest"
				>
					Explore
				</Link>
			</Row.WithImage>
			<Row.WithImage
				reverse
				className="bg-[#6E5D9F] text-[#EBE7E7]"
				image="/images/collections/range-rover-house-madelaine.jpg"
				contentClassName="space-y-14"
			>
				<h2 className="ln-subtitle">Madelaine</h2>
				<p>
					The restrained elegance of Madelaine in polished black
					patent ensures a style that endures with a memorable
					association. This model was a beloved handbag of Her Majesty
					Queen Elizabeth II who often carried it to functions and
					official engagements and has, for the first time become
					available as a commemorative piece. With its sleek
					appearance and distinctive front flap, Madelaine is a
					treasured memento. Madelaine had a star role in the Platinum
					Jubilee Celebrations, it was illustrated above Buckingham
					Palace in the form of drones beamed around the world.
				</p>
				<Link
					to="/p/3137-01"
					className="inline-block text-sm uppercase tracking-widest"
				>
					Explore
				</Link>
			</Row.WithImage>
			<Row.WithImage
				contentClassName="space-y-14"
				imageClassName="lg:m-14"
				image="/images/collections/range-rover-house-crown.jpg"
			>
				<h2 className="ln-subtitle">Launer in ‘The Crown’</h2>
				<p>
					The styles favoured by Queen Elizabeth II featured
					significantly in the award winning drama The Crown from
					Netflix for three series.
				</p>
			</Row.WithImage>
			<Products
				title="Other Styles Queen Elizabeth Carried"
				className="container"
				skus={["3098-01", "3099-02", "3046-01", "3050-09"]}
			/>
			<Products
				title="LEGACY BAGS"
				className="container"
				skus={["3097-18", "2914-14", "3127-03", "3011-04"]}
			/>
			<Row.WithImage
				reverse
				className="bg-[#343030] text-[#EBE7E7]"
				image="/images/collections/range-rover-house-legacy.jpg"
				contentClassName="space-y-14"
			>
				<h2 className="ln-subtitle">EXPLORE THE LEGACY COLLECTION</h2>
				<p>
					At Launer we believe in slow fashion. Our Legacy Collection
					represents our best selling designs that were created more
					than three decades ago and remain as relevant and popular
					today. An impeccable collection of bags that radiate and
					reinvent with colour to become investment purchases.
				</p>
				<Link
					to="/cc/legacy-collection"
					className="inline-block text-sm uppercase tracking-widest"
				>
					FIND OUT MORE
				</Link>
			</Row.WithImage>
			<Row.WithImage
				className="bg-[#908E82] text-[#EBE7E7]"
				contentClassName="space-y-12"
				imageClassName="lg:m-14"
				image="/images/collections/range-rover-house-craftsmanship.jpg"
			>
				<h2 className="ln-subtitle">A Tradition of Handcrafting</h2>
				<div className="space-y-6">
					<p>
						To own a Launer item is to own a leather product that
						has been part of a remarkable manufacturing journey with
						passion and dedication at the heart of the process.
					</p>
					<p>
						Every style is handmade by a single skilled artisan from
						start to finish, a process that requires traditional
						leather craft expertise.
					</p>
				</div>
				<Link
					to="/craftsmanship"
					className="inline-block text-sm uppercase tracking-widest"
				>
					FIND OUT MORE
				</Link>
			</Row.WithImage>
			<Row.WithImage
				className="bg-[#2C132D] text-[#EBE7E7]"
				contentClassName="space-y-14"
				image="/images/collections/range-rover-house-customise.jpg"
			>
				<h2 className="ln-subtitle">Discover Customisation</h2>
				<p>
					Get creative with the colour and material of your choice.
					Our made to order service has become so successful that we
					wanted to develop it further to provide a truly interactive
					experience. Every design, from handbag to wallet, can be
					reimagined in a colour or colours to suit your personal
					taste, including handles, straps and even the lining.
				</p>
				<Link
					to="/customise-at-launer"
					className="inline-block text-sm uppercase tracking-widest"
				>
					FIND OUT MORE
				</Link>
			</Row.WithImage>
			<Instagram className="mb-5 mt-10" />
		</div>
	);
}
