import { Popover, Transition } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/outline";
import {
	getDynamicLocaleKey,
	getMenuLocaleId,
	getSwatchGroupLabel,
	useLocalization,
} from "@launerlondon/l10n";
import cx from "classnames";
import Range from "./forms/Range";
export type FilterOption = { key: string; name: string; count: number };
export type FilterPriceValue = {
	min: number;
	max: number;
	step: number;
	values: number[];
};

type FilterProps =
	| {
			type: "category" | "material" | "color";
			show?: any;
			label: string;
			defaultValue?: string;
			options: FilterOption[];
			onChange: (value: string | undefined) => void;
	  }
	| {
			type: "price";
			show?: any;
			label: string;
			defaultValue?: FilterPriceValue;
			onChange: (values: number[]) => void;
	  };

const MenuFilterItem: React.FC<FilterProps> = (props) => {
	const { l10n } = useLocalization();

	const getLabel = (label: string, opts: { selector?: boolean } = {}) => {
		let key = label;
		if (key === "all") {
			return l10n.getString("product-filter--all");
		}
		if (props.type === "category") {
			key = getMenuLocaleId(label);
		}
		if (props.type === "material") {
			key = getDynamicLocaleKey(
				"swatch_group",
				opts.selector ? getSwatchGroupLabel(label) : label,
			);
		}
		if (props.type === "color") {
			key = `swatch-color--${key}`;
		}
		return l10n.getString(key);
	};

	if ("show" in props) {
		const shouldShow =
			typeof props.show === "function" ? props.show() : props.show;
		if (Boolean(shouldShow) === false) {
			return null;
		}
	}
	return (
		<Popover className="w-full">
			{({ open, close }) => (
				<>
					<Popover.Button
						className={cx(
							"flex w-full items-center justify-between py-4 focus:outline-none",
							"lg:py-0",
						)}
					>
						<span className="_py-1 _pr-2 h-4 rounded-l text-[#9B9797]">
							{props.label}
						</span>
						<span className="flex h-4 items-center whitespace-nowrap px-2 py-1 text-[#362E2E]">
							<span className="h-4">
								{props.defaultValue
									? props.type === "price"
										? `${props.defaultValue.values[0]?.toLocaleString()} – ${props.defaultValue.values[1]?.toLocaleString()}`
										: getLabel(props.defaultValue, {
												selector: true,
										  })
									: l10n.getString("product-filter--all")}
							</span>
							<ChevronUpIcon
								className={cx(
									"ml-1 h-4 w-4 text-gray-500",
									open && "rotate-180 transform",
								)}
							/>
						</span>
					</Popover.Button>
					<Transition
						enter="lg:transition ease-out duration-200"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="lg:transition ease-in duration-150"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Popover.Panel
							className={cx(
								"pb-4 text-xs text-gray-500",
								"lg:absolute lg:left-0 lg:right-0 lg:z-30 lg:mt-2 lg:bg-white lg:py-8 lg:shadow",
							)}
						>
							<div
								className={cx(
									props.type !== "price" &&
										"grid grid-cols-2 gap-4",
									"lg:container lg:grid-cols-6",
									//"flex flex-wrap gap-x-6 gap-y-4"
								)}
							>
								{props.type === "price" ? (
									<Range
										className="max-w-sm"
										min={props.defaultValue?.min || 0}
										max={props.defaultValue?.max || 1000}
										step={props.defaultValue?.step || 100}
										defaultValues={
											props.defaultValue?.values || [
												0, 100,
											]
										}
										onChange={props.onChange}
									/>
								) : (
									<>
										{props.options.map((c) => {
											const v =
												c.key === "all"
													? undefined
													: c.key;
											const isSelected =
												props.defaultValue === v;
											return (
												<button
													className={cx(
														"flex items-center",
														"whitespace-nowrap p-1 text-left",
													)}
													key={c.key}
													onClick={() => {
														!isSelected &&
															props.onChange(v);
														setTimeout(close, 300);
													}}
												>
													<span className="mr-2 flex h-3 w-3 items-center justify-center border border-gray-400">
														{isSelected && (
															<span className="block h-2 w-2 bg-gray-400" />
														)}
													</span>

													<span>
														{getLabel(c.name)} (
														{c.count})
													</span>
												</button>
											);
										})}
									</>
								)}
							</div>
						</Popover.Panel>
					</Transition>
				</>
			)}
		</Popover>
	);
};

export default MenuFilterItem;
