import { Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { Localized } from "@launerlondon/l10n";
import { CartItemSnapOption } from "@launerlondon/products";
import { findSku } from "@launerlondon/shared";
import { useConfig, useProduct } from "@launerlondon/shop-hooks";
import cx from "classnames";
import { useEffect, useMemo, useRef, useState } from "react";
import { useIsVisible } from "react-is-visible";

type ImageQuality = "thumb" | "medium" | "large";

type Props = {
	sku: string;
	options?: Array<CartItemSnapOption>;
	className?: string;
	showWarning?: boolean;
	imageQuality?: ImageQuality;
};
type SwatchOption = CartItemSnapOption & {
	type: "swatch" | "strap_fitting" | "gold_corners";
};

function getThumbImg(
	img?: { thumb?: string; medium?: string; large?: string } | null,
	quality?: ImageQuality,
) {
	if (quality && img?.[quality]) {
		return img[quality];
	}

	return img?.thumb?.match("640x640")
		? img.thumb
		: img?.medium?.match("640x640")
		? img.medium
		: "";
}

const ProductPreview: React.FC<Props> = (props) => {
	const skus = useConfig();
	const productImage = getThumbImg(
		useProduct(props.sku)?.images.product,
		props.imageQuality,
	);
	const hasSkuPreview = findSku(props.sku, skus.withPreview);
	const showPreview =
		props.options &&
		hasSkuPreview &&
		// falback to product image on cart share not having thumb data
		// TODO: dynamically calculate thumb data
		props.options.some((o) => o.type === "swatch" && o.meta.thumb);
	const [showWarning, setShowWarning] = useState(false);
	const [loaded, setLoaded] = useState(false);
	const itemRef = useRef<HTMLDivElement>(null);
	const isVisible = useIsVisible(itemRef);

	const bgs = useMemo(() => {
		return showPreview
			? props
					.options!.filter(
						(o): o is SwatchOption =>
							Boolean(o.type === "swatch" && o.meta.previewId) ||
							Boolean(o.type === "strap_fitting") ||
							Boolean(o.type === "gold_corners" && o.value),
					)
					// move gold_corners to last layer (above swatches)
					.sort((o) => (o.type === "gold_corners" ? 1 : -1))
					.reduce<SwatchOption[]>((p, c, i) => {
						if (i === 0) {
							const thumb = c.meta.thumb.replace(
								/_.*_.*_1280x1280/,
								"_base_1280x1280",
							);
							p.push({
								...c,
								meta: {
									id: c.meta.id,
									label: "metal",
									thumb,
								},
							});
						}
						p.push(c);
						return p;
					}, [])
					.map((o) => o.meta.thumb)
			: [productImage!];
	}, [showPreview, props.options, productImage]);

	useEffect(() => {
		if (!isVisible) return;
		setShowWarning(false);
		bgs.forEach((bg) => {
			const img = new Image(0, 0);
			img.onerror = () => {
				if (props.showWarning !== false) {
					setShowWarning(true);
				}
			};
			img.onload = () => setLoaded(true);
			img.src = bg;
		});
	}, [bgs, isVisible]);

	return (
		<div
			ref={itemRef}
			className={cx(
				"w-full pb-[100%] transition-opacity delay-300 duration-300",
				loaded ? "opacity-100" : "opacity-0",
			)}
		>
			<Transition
				show={showWarning}
				enterFrom="opacity-0"
				leaveTo="opacity-0"
				className="absolute right-2 top-2 z-10 flex gap-2 rounded bg-amber-500/80 p-2 font-sans text-xs text-white transition-opacity"
				as="div"
			>
				<ExclamationTriangleIcon className="h-4 w-4" />
				<Localized id="product-preview-not-found" />
			</Transition>
			{bgs.map((bg, idx) => (
				<div
					key={`${bg}-${idx}`}
					className={cx(
						"absolute h-full w-full bg-contain bg-center bg-no-repeat",
						props.className,
					)}
					style={{
						backgroundBlendMode: "multiply",
						backgroundImage: `url(${bg})`,
					}}
				/>
			))}
		</div>
	);
};

export default ProductPreview;
