import { useEffect, useRef } from "react";
import cx from "classnames";
import { useIsVisible } from "react-is-visible";

type Props = {
	className?: string;
};

const Instagram: React.FC<Props> = (props) => {
	const ref = useRef<HTMLDivElement>(null);
	const isVisible = useIsVisible(ref);
	useEffect(() => {
		if (!isVisible || "eapps" in self) return;
		const script = document.createElement("script");
		script.defer = true;
		script.src = "https://apps.elfsight.com/p/platform.js";
		document.body.append(script);
	}, [isVisible]);

	return (
		<div className={cx("mx-2 font-sans", props.className)}>
			<h3 className="my-4 text-center text-lg font-light uppercase">
				<a
					href="https://instagram.com/launerlondonofficial/"
					className="font-display hover:text-accent-500 tracking-widest"
					target="_blank"
					rel="noopener noreferer"
				>
					Follow us
				</a>
			</h3>
			<div
				ref={ref}
				className="elfsight-app-bde7d13c-9188-47d3-9699-b359450621b5"
			/>
		</div>
	);
};

export default Instagram;
