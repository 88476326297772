import { useMetaTags } from "@launerlondon/shop-hooks";
import { NavLink } from "react-router-dom";
import { ParallaxBanner } from "react-scroll-parallax";
import bannerUrl from "../assets/banner-delivery.jpg";
import imagePackaging from "../assets/delivery-packaging.jpg";

const timings = [
	{
		label: "Handbags",
		time: "3–4 weeks",
	},
	{
		label: "Pocket Goods",
		time: "2–3 weeks",
	},
];

const Delivery: React.FC = () => {
	useMetaTags({
		title: "Delivery information",
		description:
			"We use courier services to deliver the majority of our leather goods. All parcels dispatched will be delivered Monday to Friday between 8.00am-6.00pm (excluding Bank Holidays) and will require a signature upon delivery.",
		image: bannerUrl,
	});
	return (
		<div>
			<ParallaxBanner
				layers={[
					{
						speed: -20,
						expanded: false,
						children: (
							<div
								className="ln-banner-image"
								style={{ backgroundImage: `url(${bannerUrl}` }}
							/>
						),
					},
				]}
				className="ln-banner"
			/>
			<div className="container">
				<header className="ln-intro-grid">
					<h2 className="font-display">Delivery Information</h2>
					<div>
						<p>
							We use courier services to deliver the majority of
							our leather goods. All parcels dispatched will be
							delivered Monday to Friday between 8.00am-6.00pm
							(excluding Bank Holidays) and will require a
							signature upon delivery.
						</p>
					</div>
				</header>
				<div className="ln-section-grid my-20 gap-10">
					<div>
						<img src={imagePackaging} />
					</div>
					<div>
						<div className="border-b pb-6">
							<h3 className="ln-subtitle mb-4 mt-4 lg:mt-0">
								We currently anticipate an estimated dispatch
								time from date of order as follows:
							</h3>
							<div className="rounded bg-gray-100 p-4">
								<table className="w-full">
									<tbody className="divide-y">
										{timings.map((r) => (
											<tr key={r.label}>
												<td>{r.label}</td>
												<td>{r.time}</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
						<div>
							<p>
								If you know that no one will be available to
								sign for your delivery, please supply an
								alternative delivery address when placing your
								order. If no one is available to sign for the
								goods at the delivery address, our courier will
								leave a card. Simply call the number on the
								card, quoting the card reference number to
								re-arrange delivery.
							</p>
							<p>
								Although we work closely with our couriers to
								ensure the best possible service and convenient
								delivery times, we regret that delays sometimes
								occur which are outside our control.
							</p>
							<p>
								We are unable to change the delivery address
								once the order has been processed. If you have
								any further questions regarding the delivery of
								your Launer product, please use the form on our
								contact page.
							</p>
						</div>

						<article className="space-y-4 border-t pt-6">
							<h3 className="ln-subtitle">
								Delivery information for overseas orders
							</h3>
							<p>
								Local taxes and duties will be included in the
								total price for customers delivering their
								products into the UK, mainland Europe, USA,
								Australia, Hong Kong and China. A breakdown of
								these charges can be seen at checkout.
							</p>
							<p>
								Orders to countries outside these regions may
								carry duties which are payable by the recipient
								upon delivery, we are unable to advise on these
								potential sums. If you have any further
								enquiries please contact us via the{" "}
								<NavLink to="/contact-launer">
									Contact Us page
								</NavLink>
								.
							</p>
						</article>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Delivery;
