import { useEffect, useState } from "react";

type Config = typeof import("@launerlondon/products/src/skus").default;

export function useConfig() {
	const [config, setConfig] = useState<Config>({
		hidden: [],
		handbags: [],
		handbagsWithStraps: [],
		vouchers: [],
		with360: [],
		withEmblem: [],
		withCrystalEmblem: [],
		withPassportFoiling: [],
		withPhoneSize: [],
		withLining: [],
		withSingleGoldCorners: [],
		withStrapFitting: [],
		withStrapStyle: [],
		withTabletSize: [],
		withWalletSize: [],
		withPreview: [],
		withInternalImage: [],
		withPalladiumFittings: [],
	});
	useEffect(() => {
		import("@launerlondon/products/src/skus").then((d) =>
			setConfig(d.default),
		);
	}, []);
	return config;
}
