import { twMerge } from "tailwind-merge";

type WithImageProps = React.PropsWithChildren & {
	reverse?: boolean;
	className?: string;
	containerClassName?: string;
	contentClassName?: string;
	imageClassName?: string;
	image?: React.ReactNode | string;
};

function WithImage(props: WithImageProps) {
	return (
		<div className={props.className}>
			<div
				className={twMerge(
					"lg:container lg:grid lg:grid-cols-2",
					props.containerClassName,
				)}
			>
				<div
					className={twMerge(
						"relative",
						"place-self-stretch",
						"row-start-1",
						"aspect-[4/3.5]",
						"bg-black/10 bg-cover bg-center bg-no-repeat",
						props.imageClassName,
					)}
					style={
						typeof props.image === "string"
							? { backgroundImage: `url(${props.image})` }
							: undefined
					}
					children={
						typeof props.image === "string" ? null : props.image
					}
				/>
				<div
					className={twMerge(
						"row-start-1",
						"place-self-center",
						"max-w-xl space-y-4 py-10 lg:px-10",
						"max-lg:container",
						props.reverse && "col-start-1",
						props.contentClassName,
					)}
				>
					{props.children}
				</div>
			</div>
		</div>
	);
}

type SingleProps = React.PropsWithChildren & {
	className?: string;
	contentClassName?: string;
};

function Single(props: SingleProps) {
	return (
		<div className={props.className}>
			<div
				className={twMerge(
					"container grid max-w-2xl",
					"place-items-center text-center",
					"space-y-4 py-10",
					props.contentClassName,
				)}
			>
				{props.children}
			</div>
		</div>
	);
}

export default { WithImage, Single };
