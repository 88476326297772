import { AppLoaderData, routes } from "@launerlondon/shop-router";
import cx from "classnames";
import { useFetcher, useRouteLoaderData } from "react-router-dom";

type Props = {
	className?: string;
};

const ListViewControl: React.FC<Props> = (props) => {
	const { listViewMode } = useRouteLoaderData(routes.home) as AppLoaderData;
	const fetcher = useFetcher();
	return (
		<div className={cx("flex gap-4", props.className)}>
			{[
				{
					title: "Minimal view",
					value: "loose",
					path: "M6 10v6H0v-6h6Zm10 0v6h-6v-6h6ZM6 0v6H0V0h6Zm10 0v6h-6V0h6Z",
				},
				{
					title: "Expanded view",
					value: "tight",
					path: "M4 12v4H0v-4h4Zm6 0v4H6v-4h4Zm6 0v4h-4v-4h4ZM4 6v4H0V6h4Zm6 0v4H6V6h4Zm6 0v4h-4V6h4ZM4 0v4H0V0h4Zm6 0v4H6V0h4Zm6 0v4h-4V0h4Z",
				},
			].map((b) => (
				<button
					key={b.title}
					title={b.title}
					onClick={() => {
						fetcher.submit(
							{ listViewMode: b.value },
							{ action: routes.home, method: "post" },
						);
					}}
					className={cx(
						"h-4 w-4 active:fill-gray-500",
						b.value === listViewMode
							? "fill-gray-800"
							: "fill-gray-300",
					)}
				>
					<svg viewBox="0 0 16 16">
						<path d={b.path} />
					</svg>
				</button>
			))}
		</div>
	);
};

export default ListViewControl;
