import { Localized } from "@launerlondon/l10n";
import { useCookieConsent } from "@launerlondon/shop-hooks";
import cx from "classnames";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { isDNT } from "../lib/utils";

type Props = {
	onSettingsClick?: () => void;
};

const CookieBar: React.FC<Props> = (props) => {
	const toastId = "cookieBar";
	const [consent, setConsent] = useCookieConsent();

	useEffect(() => {
		if (consent.essential) {
			toast.dismiss(toastId);
			return;
		}
		const id = toast.custom(
			(t) => (
				<div
					className={cx(
						t.visible ? "animate-enter" : "animate-leave",
						"flex w-full flex-col items-center rounded-lg",
						"px-4 py-2",
						"bg-black text-center font-sans text-xs text-white",
						"md:flex-row md:text-left",
					)}
				>
					<Localized
						id="cookie-bar-text"
						elems={{
							a: (
								<a
									className="underline"
									href="https://launer.com/terms-conditions-privacy"
									target="_blank"
								/>
							),
						}}
						children={
							<p className="mb-4 max-w-2xl md:mb-0 md:mr-4" />
						}
					/>
					<div className="flex w-full flex-col md:my-0 md:ml-auto md:w-auto md:flex-row">
						<button
							className="button button-ghost relative min-w-[260px] overflow-auto border text-xs md:mr-4"
							onClick={() =>
								setConsent({
									essential: true,
									analytics: !isDNT,
									marketing: !isDNT,
								})
							}
						>
							<Localized
								id={
									isDNT
										? "cookie-bar-button--accept-essential"
										: "cookie-bar-button--accept-all"
								}
								elems={{
									s: (
										<small
											className={cx(
												"absolute block ",
												"-top-2 left-1/2 -translate-x-1/2 px-2",
												"rounded-sm bg-black text-white",
											)}
										/>
									),
								}}
								children={<span />}
							/>
						</button>
						<button
							className="button button-ghost border text-xs"
							onClick={props.onSettingsClick}
						>
							<Localized id="cookie-bar-button--custom" />
						</button>
					</div>
				</div>
			),
			{
				id: toastId,
				position: "bottom-left",
				duration: Infinity,
			},
		);
		return () => toast.dismiss(id);
	}, [consent]);

	return null;
};

export default CookieBar;
