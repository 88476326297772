import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { findDeep } from "./utils";

export type MenuItem = {
	title: string;
	url?: string;
	children?: MenuItem[];
};

const list = import("../../assets/menu.json").then<MenuItem[]>(
	(d) => d.default,
);

export async function getMenuItems() {
	return list;
}

export function useMenu() {
	const [items, setItems] = useState<MenuItem[]>([]);
	useEffect(() => void getMenuItems().then(setItems), []);
	return items;
}

export function useMenuParentCategories() {
	const { pathname } = useLocation();

	const [items, setItems] = useState<MenuItem[]>([]);
	useEffect(() => {
		if (!pathname) return;
		getMenuParentCategories(pathname).then(setItems);
	}, [pathname]);
	return items;
}

export async function getMenuParentCategories(url: string) {
	const parents: MenuItem[] = [];
	const item = findDeep(
		await getMenuItems(),
		"url",
		url,
		"children",
		parents,
	);
	return item ? [...parents, item] : [];
}
