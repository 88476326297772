import SimpleCountDown from "react-countdown-simple";
import { TRenderProps } from "react-countdown-simple/dist/esm/types";
import cx from "classnames";

type Props = { date: string };

const time = (t: number) => t.toString().padStart(2, "0").split("");

const renderer: TRenderProps = (props) => {
	const date = [
		{ title: "days", date: time(props.days) },
		{ title: "hours", date: time(props.hours) },
		{ title: "minutes", date: time(props.minutes) },
	];
	return (
		<div
			className={cx(
				"absolute bottom-0 left-0 right-0 select-none",
				"flex items-center justify-center p-8 pt-12",
				"bg-gradient-to-t from-black/80 via-black/60",
				"gap-7 lg:gap-10 xl:gap-14",
			)}
		>
			{date.map((d) => (
				<div key={d.title}>
					<h4
						className={cx(
							"mb-1 text-white",
							"text-[11px] font-normal tracking-widest",
							"xl:text-sm xl:font-light",
						)}
					>
						{d.title}
					</h4>
					<div
						className={cx(
							"font-display flex",
							"gap-1 text-lg font-normal",
							"xl:gap-2 xl:text-4xl xl:font-light",
						)}
					>
						{d.date.map((t, idx) => (
							<div
								key={idx}
								className={cx(
									"grid place-items-center bg-white",
									"h-10 w-8",
									"xl:h-14 xl:w-12",
								)}
								children={t}
							/>
						))}
					</div>
				</div>
			))}
		</div>
	);
};

const Countdown: React.FC<Props> = (props) => {
	if (new Date() > new Date(props.date)) return null;
	return <SimpleCountDown targetDate={props.date} renderer={renderer} />;
};

export default Countdown;
