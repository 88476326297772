import { useLocalization } from "@launerlondon/l10n";
import { CartItemSnap, fmtCartItem, Product } from "@launerlondon/products";
import { routes } from "@launerlondon/shop-router";
import { MarketingStatus } from "@launerlondon/shop-types";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import { NewsletterSignup } from "../components";
import { trackAddToCart } from "../lib/analytics";
import { addCartItem } from "../redux/actions";
import cx from "classnames";
import { KlarnaCTA } from "@launerlondon/shop-components";

type Props = { className?: string; items: CartItemSnap[]; product: Product };

const AddToCartButton: React.FC<Props> = (props) => {
	const { items, product, className } = props;
	const dispatch = useDispatch();
	const { l10n } = useLocalization();
	const navigate = useNavigate();
	const [green, setGreen] = useState(false);

	// reset button once item.options change
	useEffect(() => {
		setGreen((g) => (g ? false : g));
	}, [items[0]?.options]);

	const addItemId = (i: CartItemSnap) =>
		dispatch<any>(addCartItem(i)) as unknown as CartItemSnap;

	const addToCart = useCallback(() => {
		if (green) {
			navigate(generatePath(routes.checkout));
			return;
		}

		items
			.map(addItemId)
			.forEach((snap) => trackAddToCart(fmtCartItem(product, snap)));

		setGreen(true);
	}, [navigate, items, green, product]);

	return (
		<div className="max-lg:mx-auto ">
			<button
				className={cx("button", green && "bg-green", className)}
				onClick={addToCart}
				children={l10n.getString(
					green ? "product-button--clicked" : "product-button",
				)}
			/>
			<KlarnaCTA className="ml-1" />
			{green && (
				<NewsletterSignup
					showUnless={MarketingStatus.RefusedAddToCart}
				/>
			)}
		</div>
	);
};

export default AddToCartButton;
