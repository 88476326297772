import { useProductListParams } from "@launerlondon/shop-hooks";
import { twJoin } from "tailwind-merge";

type Field = { title: string; description: string };

type Data = Record<
	string,
	{
		header: Field;
		divider?: Field;
		footer?: Array<Field>;
	}
>;

const data: Data = {
	handbags: {
		header: {
			title: "Handbags",
			description:
				"Our women’s bags are a celebration of the finest in British heritage. Luxurious, timeless  pieces, crafted by hand in the heart of England in a listed Edwardian building for more than 80 years.",
		},
		divider: {
			title: "Customisation",
			description:
				"Every article in our collection, from handbags, to purses, wallets and accessories can be reimagined in a colour(s) and leather finish of your choice. It’s a luxury milestone, unique to Launer and handcrafted to our renowned impeccable standard. There is no additional cost for this bespoke service, should you opt for the same calf or patent leather finishes.",
		},
		footer: [
			{
				title: "A handbag by Launer",
				description:
					"The beauty of a British made handbag from Launer lies in its superb craftsmanship and meticulous attention to detail; a leather handbag that endures over time retaining its beauty with a style that transcends trends.",
			},
			{
				title: "Customisation",
				description:
					"The term designer handbag is naturally attributed to our <a href='/c/top-handle-bags'>top handle<a/>, <a href='/c/cross-body-bags'>cross body</a>, <a href='/c/clutch-bags'>clutch</a> and evening styles but at Launer, they represent this and much more. Colour customisation is a service we have pioneered which enables customers to create a piece that is personal to them and can be applied to our luxury handbags as well as our women’s <a href='/c/purses'>purses</a>, <a href='/c/acessories'>leather accessories</a> or even our essential <a href='/c/women-card-holders'>ladies card holders</a>. We can add an additional flourish with gold foil lettering that is hand embossed on selected products.",
			},
			{
				title: "Quiet Luxury",
				description:
					"Launer is proud of its quiet luxury bag status, we make only to order, upholding a sustainable approach, eliminating waste. When you buy a Launer women’s leather bag, it comes with a lifetime warranty, it is neither discounted or sells at a reduced price which gives you complete assurance in our bags – a sign of excellence.",
			},
		],
	},
	"top-handle-bags": {
		header: {
			title: "Top Handle Bags",
			description:
				"Our designer top handle bags are an enduring symbol of ladylike elegance. They elevate any look into a stylish ensemble with a timeless sensibility.",
		},
		divider: {
			title: "Ultimate versatility",
			description:
				"Top handle handbags look every bit as refined when they are worn cross body with their complimentary attachable strap. Invest in your wardrobe with a beautiful leather top handle bag that will remain an essential for years to come.",
		},
	},
	"clutch-bags": {
		header: {
			title: "Clutch Bags",
			description:
				"A designer clutch bag is the perfect accessory by day or night. Beautifully understated, it’s a wardrobe must-have and at Launer has been perfected. Every one of our leather clutch bags comes with an attachable chain or strap as well as a cosmetic mirror, ideal for on the go.",
		},
		footer: [
			{
				title: "A renaissance moment",
				description:
					"The luxury clutch is enjoying something of a renaissance at this time, often carried by Queen Camilla at events and state occasions. At Launer, we are the home of the elegant clutch bag with a host of styles to suit any occasion.",
			},
		],
	},
	"shoulder-bags": {
		header: {
			title: "Shoulder Bags",
			description:
				"The indispensable women’s shoulder bag is a must-have in any wardrobe, a beautifully versatile accessory by day or by night. At Launer, as you would expect, our leather shoulder bags are refined and supremely crafted by our skilled artisans in our owned factory using the finest Italian leather. Adopting many guises, they come in a variety of sizes and styles from micro to shopper with stunning elegance.",
		},
		footer: [
			{
				title: "Deluxe day bags",
				description:
					"Our top handle bags all feature an attachable shoulder strap option and double handle styles are designed to also be carried by hand. From the ladylike poise of the Turandot to the casual luxe of Elizabeth, the signature house rope emblem is adorned along with a complimentary cosmetic mirror and protective dust bag making these the ultimate in designer shoulder bags.",
			},
			{
				title: "Sleek yet spacious",
				description:
					"For those seeking a small shoulder bag then Angelika or Emma are perfect whilst those wanting larger shoulder bags have Lydia and Sue to accommodate their needs. When you buy from Launer you are investing in a shoulder purse that will be part of your wardrobe for years to come.",
			},
		],
	},
	"evening-bags": {
		header: {
			title: "Evening Bags",
			description:
				"For those seeking the ultimate designer evening bag, Launer has a stunning array that are truly exquisite. Crafted in England by hand, our collection includes metallic <a href='/c/clutch-bags'>clutches</a>, patent <a href='/c/shoulder-bags'>shoulder bags</a> and elegant <a href='/c/top-handle-bags'>top handle</a> styles.",
		},
		divider: {
			title: "An item to treasure",
			description:
				"Our range is perfection: every piece is an iconic accessory that will ensure you make a stellar entrance to any event. Our women’s evening bag styles transcend time; a collectable item that goes beyond an everyday staple.",
		},
	},
	"cross-body-bags": {
		header: {
			title: "Cross Body Bags",
			description:
				"The essential women’s cross body bag becomes an elevated style statement at Launer. Every one of our luxury leather cross body bags is delivered with a complimentary cosmetic mirror, adjustable strap and embossed leather plaque. A structured form also ensures this style retains a sophisticated air with a range of sizes to offer.",
		},
		footer: [
			{
				title: "Unprecedented degree of workmanship",
				description:
					"Every piece is handcrafted in England by skilled artisans using traditional methods. Remember, our colour customisation service can transform your cross body purse into a unique timeless piece.",
			},
		],
	},
	purses: {
		header: {
			title: "Purses",
			description:
				"An exquisite handbag requires an exquisite purse to match. At Launer, we have a beautiful array of women’s purses which are handmade using traditional methods and luxury leather. Designed in different sizes to fit into our bags, our collection includes card cases alongside coin purses and pouches, all featuring our signature hallmarks.",
		},
	},
	"women-card-holders": {
		header: {
			title: "Women’s card holders",
			description:
				"The essential ladies card holder is a refined alternative to a purse, reassuringly handmade in England. Our leather card holders look stylish and elegant with the ability to store multiple cards and place discreetly in a Launer handbag or clothing pocket.",
		},
		footer: [
			{
				title: "The perfect gift",
				description:
					"An indispensable, everyday accessory that is increasingly used, retaining a feminine appeal. The women’s credit card case makes an ideal gift for loved ones.",
			},
		],
	},
	"men-card-holders": {
		header: {
			title: "Men’s card holders",
			description:
				"A must-have item for any discerning man has to be the cardholder wallet, a refined option reassuringly handmade in England. Our leather card holder looks stylish and elegant with the ability to store multiple cards and place discreetly in a jacket, coat or trouser pocket.",
		},
		footer: [
			{
				title: "The perfect gift",
				description:
					"An indispensable, everyday accessory that is increasingly used, at Launer our designer cardholders are a distinguished affair. Making a considered gift; our luxury cardholder is presented in a gift box with a protective dust cover.",
			},
		],
	},
	wallets: {
		header: {
			title: "Wallets",
			description:
				"Our storied brand, as you would expect, boasts a distinguished array of men’s wallets. Often referred to as the finest designer wallets, our collection exudes an understated luxury. Every piece is handmade in England featuring our signature turned-edge leather work. There is a style to suit all men and their requirements including bifold designs, coin purses, classic pocket wallets and breast pocket wallets.",
		},
		footer: [
			{
				title: "A considered gift",
				description:
					"A luxury British leather wallet has to be one of the most appreciated gifts and is a highly sought after purchase.",
			},
		],
	},
};

function useSection() {
	const { search, slug, byStyle } = useProductListParams();
	const name = search || slug;
	return name && data[name] ? { ...data[name], name, byStyle } : undefined;
}

const Header: React.FC = () => {
	const section = useSection();
	if (!section?.header) return null;
	return (
		<div className="bg-gray-50">
			<div className="container grid gap-4 max-lg:py-8 lg:grid-cols-2">
				<div className="grid items-center">
					<div className="space-y-2">
						<h1 className="ln-subtitle">
							{section.header.title}{" "}
							{section.byStyle && "by style"}
						</h1>
						<p
							className="text-sm lg:max-w-xl [&_a]:underline"
							dangerouslySetInnerHTML={{
								__html: String(section.header.description),
							}}
						/>
					</div>
				</div>
				<div
					className="aspect-[4/2] bg-gray-50 bg-cover bg-center max-lg:hidden"
					style={{
						backgroundImage: `url(/hero/${section.name}.webp)`,
					}}
				/>
			</div>
		</div>
	);
};

const Divider: React.FC<{ className?: string }> = (props) => {
	const section = useSection();
	if (!section?.divider) return null;
	return (
		<div className={props.className}>
			<div
				className={twJoin(
					"grid md:grid-cols-2",
					"overflow-hidden",
					"rounded bg-gray-50",
				)}
			>
				<div
					className="aspect-[4/3] bg-gray-100 bg-cover bg-center"
					style={{
						backgroundImage: `url(/hero/${section.name}-divider.webp)`,
					}}
				/>
				<div
					className={twJoin(
						"grid items-center text-center",
						"max-lg:p-4 max-lg:pb-8",
					)}
				>
					<div
						className={twJoin(
							"mx-auto space-y-4",
							"md:max-w-sm",
							"lg:space-y-6",
							"xl:max-w-[470px]",
						)}
					>
						<h3 className="font-[400]">{section.divider.title}</h3>
						<p
							className="text-xs [&_a]:underline"
							dangerouslySetInnerHTML={{
								__html: String(section.divider.description),
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const Footer: React.FC = () => {
	const section = useSection();
	if (!section?.footer) return null;

	return (
		<div className="border-t">
			<div className="container grid gap-8 py-10 lg:grid-cols-3">
				{section.footer?.map((s) => (
					<div key={s.title} className="space-y-2">
						<h4>{s.title}</h4>
						<p
							className="text-xs text-gray-500 [&_a]:underline"
							dangerouslySetInnerHTML={{
								__html: String(s.description),
							}}
						/>
					</div>
				))}
			</div>
		</div>
	);
};

export default { Header, Divider, Footer, keys: Object.keys(data) };
